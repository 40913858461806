import React, { memo } from 'react'
import classNames from 'classnames'
import { Button, Svg } from '../..'
import * as styles from './sortButton.module.scss'

const SortButton = ({ isActive = false, onClick }: SortButtonProps) => (
   <Button
      className={classNames(styles.sortButton, {
         [styles.sortButtonActive]: isActive,
      })}
      variant="plain"
      preserveText
      onClick={onClick}
      minWidth="auto"
      type="button"
   >
      <span
         className={classNames(styles.sortButtonIcon, {
            [styles.sortButtonIconActive]: isActive,
         })}
      >
         <Svg id="sort" />
      </span>
      <span>Sort</span>
   </Button>
)

export default memo(SortButton)
