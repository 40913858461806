import { Item } from '../../../types'

export const prepareUpdateItemObject = (obj: Item) => {
   return {
      id: obj?.Id,
      title: obj?.Title,
      toolType: obj?.ToolType,
      quantity: obj?.Quantity,
      reorderLevelQuantity: obj?.ReorderLevelQuantity,
      manufacturer: obj?.Manufacturer,
      category: obj?.Category,
      updateLocation: obj?.updateLocation,
      //optional
      description: obj?.Description,
      modelNumber: obj?.ModelNumber,
      purchaseDate: obj?.PurchaseDate,
      purchasePrice: obj?.PurchasePrice,
      serialNumber: obj?.SerialNumber,
      attachmentUrl: obj?.AttachmentUrl,
      websiteUrl: obj?.WebSiteURL,
      warrantyDate: obj?.WarrantyDate,
      barcode: obj?.Barcode,
      isRentalTool: obj?.IsRentalTool,
      rentalDate: obj?.RentalDate,
      rentalReturnDate: obj?.RentalReturnDate,
      rentalRate: obj?.RentalRate,
      rentalPeriod: obj?.RentalPeriod,
      lastAuditDate: obj?.LastAuditDate,
      lastAuditCondition: obj?.LastAuditCondition,
      vendor: obj?.Vendor,
      defaultLocation: obj?.DefaultLocation,
      //custom settings based on user settings
      customDate1Value: obj?.CustomDate1Value,
      customDate2Value: obj?.CustomDate2Value,
      customText1Value: obj?.CustomText1Value,
      customText2Value: obj?.CustomText2Value,
      customURL1Value: obj?.CustomURL1Value,
      customURL2Value: obj?.CustomURL2Value,
      customRentalRate1: obj?.CustomRentalRate1,
      customRentalRate2: obj?.CustomRentalRate2,
      customRentalRate3: obj?.CustomRentalRate3,
      customRentalRate4: obj?.CustomRentalRate4,
   }
}
