import React, { useRef } from 'react';
import Select, { createFilter } from 'react-select';

import { FieldWrapper } from '..';

import { Props } from './nonLabelDropdown.type';
import styles from './nonLabelDropdown.module';


// The non label dropdown has the same functionality as as the standard dropdown component but with different styles eg centered text and no label displayed in
// a value container for placeholder this results in some code duplication but we can't keep extending the same standard dropdown component.

const NonLabelDropdown: React.FC<Props> = React.forwardRef((props, ref) => {
    const inputRef = useRef();

    const {
        className,
        defaultValue,
        description,
        insetDescription = false,
        disabled = false,
        error,
        isClearable = true,
        isLoading,
        isMulti,
        label,
        id,
        name,
        noMargin = false,
        onChange,
        onTextChange,
        options,
        required,
        withWrappers = true,
        menuPosition = null,
        maxMenuHeight = 215,
        minMenuHeight = 215,
        menuPlacement = 'bottom',
        placeholder,
        excludeOptionValueFromSearch = false,
    } = props;

    const elRef = ref || inputRef;

    

    const renderElement = () => {
       
        return (
            <Select
                filterOption={
                  excludeOptionValueFromSearch ?
                  createFilter(
                    {matchFrom: 'any', stringify: option => `${option.label}`} // stops the search from filtering on ID
                ) : createFilter({matchFrom: 'any', stringify: option => `${option.value} ${option.label}`})}
                className={className}
                isClearable={isClearable}
                isLoading={isLoading}
                isMulti={isMulti}
                name={name}
                onChange={onChange}
                onBlur={onTextChange ? (e) =>  onTextChange(e.target.value, name) : null}
                options={options}
                ref={elRef}
                styles={styles}
                value={defaultValue}
                placeholder={required ? `${placeholder} *` : placeholder}
                isDisabled={disabled}
                menuPosition={menuPosition}
                menuPlacement={menuPlacement}
                maxMenuHeight={maxMenuHeight}
                minMenuHeight={minMenuHeight} 
            />
        );
    }

    if (!withWrappers) {
        return renderElement();
    }

    return (
        <FieldWrapper
            description={description}
            insetDescription={insetDescription}
            disabled={disabled}
            error={error}
            id={id}
            required={required}
            noMargin={noMargin}
        >
            {renderElement()}
        </FieldWrapper>
    )
});

export default NonLabelDropdown;