import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-grid-system'
import { addDays, differenceInDays, subDays } from 'date-fns'

import { Accordion } from '../..'
import { DateInput } from '../../form/fields'

type DateProps = {
   defaultValue?: Date
   error?: string
   id: string
   placeholder?: string
   maxEndDate?: Date
}

interface Props {
   className?: string
   dateProps: {
      from: DateProps
      to: DateProps
   }
   dateThreshold?: number
   id: string
   onChange: (id: string, label: string, date: Date) => void
   title: string
}

const DateRangeFilter: React.FC<Props> = ({
   className,
   dateProps,
   dateThreshold = null,
   id,
   onChange,
   title,
}) => {
   const [isOpenInitally, setIsOpenInitially] = useState<boolean>(false)
   const { from, to } = dateProps

   const handleFilterChange = (name: string, label: string, date: Date) => {
      onChange(name, label, date)
   }

   // const maxEndDate = differenceInDays(new Date(), from?.defaultValue) > dateThreshold ? addDays(from?.defaultValue, dateThreshold) : new Date(); bugfix #15140 significance of threshold ???
   const maxEndDate =
      id === 'DueBackDate' ? addDays(new Date(), 365) : new Date()
   const minStartDate = !!to?.defaultValue
      ? subDays(to?.defaultValue || new Date(), dateThreshold)
      : null

   useEffect(() => {
      setIsOpenInitially(
         !!dateProps?.from.defaultValue || !!dateProps?.to?.defaultValue
      )
   }, [])

   return (
      <Accordion
         className={className}
         id={id}
         title={title}
         isOpen={isOpenInitally}
      >
         <Row>
            <Col sm={6}>
               <DateInput
                  id={from.id}
                  maxDate={new Date()}
                  minDate={minStartDate}
                  onChange={(date) =>
                     handleFilterChange(from.id, 'From date', date)
                  }
                  noMargin
                  // @ts-ignore
                  selectedDate={from?.defaultValue}
                  error={from?.error}
                  placeholder={from?.placeholder}
               />
            </Col>
            <Col sm={6}>
               <DateInput
                  id={to.id}
                  maxDate={to.maxEndDate || maxEndDate}
                  onChange={(date) =>
                     handleFilterChange(to.id, 'End date', date)
                  }
                  noMargin
                  // @ts-ignore
                  selectedDate={to?.defaultValue}
                  error={to?.error}
                  placeholder={to?.placeholder}
               />
            </Col>
         </Row>
      </Accordion>
   )
}

export default DateRangeFilter
