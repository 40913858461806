export type ReorderItem = {
   value: string
   label: string
}

const renderOrderedTableColumns = (columns: any[], columnIds: string[]) => {
   return columnIds.reduce((columnsToRender, id: string) => {
      const column = columns?.find((col) => col?.id === id)

      return !!column ? [...columnsToRender, column] : columnsToRender
   }, [])
}

const generateColumnItemOptions = (
   colList: string[],
   columns: any[],
   ignoreCTAColumn?: boolean
): ReorderItem[] => {
   if (ignoreCTAColumn) {
      colList = colList.filter((x) => x !== 'CTA')
   }
   return colList.reduce((colsToReturn, Id) => {
      const tableCol = columns?.find((col) => col?.id === Id)
      if (tableCol) {
         colsToReturn.push({
            value: tableCol.id,
            label: tableCol.headerTitle ?? tableCol.Header,
         })
      }
      return colsToReturn
   }, [])
}

export { renderOrderedTableColumns, generateColumnItemOptions }
