import React, { ChangeEvent, useEffect, useState } from 'react';

import { Accordion } from '../..';
import { Input } from '../../form/fields';

interface Props {
    className?: string;
    defaultValue?: string;
    error?: string;
    id: string;
    onChange: (name: string, label: string, value: string) => void;
    title: string;
}

const TextFilter: React.FC<Props> = ({
    className,
    defaultValue,
    error,
    id,
    onChange,
    title
}) => {
    const [isOpenInitally, setIsOpenInitially] = useState<boolean>(false);

    const handleFilterChange = (event: ChangeEvent<HTMLFormElement>) => {
        onChange(id, title, event?.target?.value);
    }

    useEffect(() => {
        setIsOpenInitially(!!defaultValue);
    }, [])

    return (
        <Accordion
            className={className}
            id={id}
            isOpen={isOpenInitally}
            title={title}
        >
            <Input
                id={id}
                onChange={handleFilterChange}
                noMargin
                value={defaultValue}
                error={error}
                isControlled
            />
        </Accordion>
    );
};

export default TextFilter;