import React, { useEffect, useState } from 'react'
import Drawer from '../../../common/drawer/drawer'
import SlidePanel from '../../../common/slidePanel/slidePanel'
import { Option } from '../../../common/form/fields/dropdown/dropdown.type'
import {
   SortDirection,
   SortObject,
} from '../../../../hooks/useFilters/useFilters.type'
import NonLabelDropdown from '../../../common/form/fields/nonLabelDropdown/nonLabelDropdown'

interface ColumnSortPanelProps {
   isSortPanelOpen: boolean
   onClose: () => void
   columnOptions: Option[]
   sortRef: React.MutableRefObject<HTMLFormElement>
   handleSortReset: () => void
   activeSortHeader: SortObject
   handleApplySortOptions: (headerTitle: string, direction: string) => void
}

export const ColumnSortPanel = ({
   isSortPanelOpen,
   onClose,
   handleSortReset,
   handleApplySortOptions,
   columnOptions = [],
   sortRef,
   activeSortHeader,
}: ColumnSortPanelProps) => {
   const sortDirectionOptions = [
      { label: 'Ascending', value: SortDirection.ASCENDING },
      { label: 'Descending', value: SortDirection.DESCENDING },
   ]

   const [selectedSortDirection, setSelectedSortDirection] =
      useState<Option>(null)
   const [selectedColumnOption, setSelectedColumnOption] =
      useState<Option>(null)

   useEffect(() => {
      setSelectedColumnOption(
         columnOptions.find(
            (option) =>
               option.value.toLowerCase() ===
               activeSortHeader.sortBy.toLowerCase()
         )
      )

      setSelectedSortDirection(
         sortDirectionOptions.find(
            (option) => option.value === activeSortHeader.sortDirection
         )
      )
   }, [activeSortHeader, columnOptions])

   return (
      <SlidePanel isOpen={isSortPanelOpen} onClose={onClose}>
         <Drawer
            heading="Sort"
            icon={null}
            onReset={handleSortReset}
            onSave={() =>
               handleApplySortOptions(
                  selectedColumnOption.value,
                  selectedSortDirection.value
               )
            }
         >
            <form ref={sortRef}>
               <div style={{ padding: '1rem 1rem 1rem 0.25rem' }}>
                  <p>
                     Select the column you wish to sort by, then select
                     Ascending or Descending sort. For more sort options, export
                     to Excel.
                  </p>
                  <p style={{ marginBottom: '.5rem' }}>Sort By</p>
                  <NonLabelDropdown
                     isMulti={false}
                     id="columns"
                     label="Select Column"
                     name={`selectColumn`}
                     onChange={(option) => setSelectedColumnOption(option)}
                     options={columnOptions}
                     isClearable={false}
                     withWrappers={false}
                     placeholder="Select Column"
                     excludeOptionValueFromSearch={true}
                     defaultValue={selectedColumnOption}
                  />
                  <div style={{ marginBottom: '.75rem' }}></div>
                  <NonLabelDropdown
                     isMulti={false}
                     id="sortDirection"
                     label="Sort Direction"
                     name={`sortDirection`}
                     onChange={(option) => setSelectedSortDirection(option)}
                     options={sortDirectionOptions}
                     isClearable={false}
                     withWrappers={false}
                     defaultValue={selectedSortDirection}
                     excludeOptionValueFromSearch={true}
                  />
               </div>
            </form>
         </Drawer>
      </SlidePanel>
   )
}
