import React from 'react'
import classNames from 'classnames'
import { BiColumns } from 'react-icons/bi'

import { Button } from '../..'

import * as styles from './columnsButton.module.scss'
import { ColumnsButtonProps } from './columnsButton.type'

const ColumnsButton = ({ isActive = false, onClick }: ColumnsButtonProps) => (
   <Button
      className={classNames(styles.columnsButton, {
         [styles.columnButtonActive]: isActive,
      })}
      variant="plain"
      onClick={onClick}
      minWidth="auto"
      preserveText
   >
      <span
         className={classNames(styles.columnsButtonIcon, {
            [styles.columnsButtonIconActive]: isActive,
         })}
      >
         <BiColumns />
      </span>
      <span>Columns</span>
   </Button>
)

export default ColumnsButton
