import React from 'react'

import Items from '../../components/views/items/items'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function ItemsPage() {
   return (
      <LayoutLoggedIn>
         <Items />
      </LayoutLoggedIn>
   )
}
