import React, { memo } from 'react'
import classNames from 'classnames'

import { Button, Svg } from '../..'

import * as styles from './filterButton.module.scss'

const FilterButton = ({ isActive = false, onClick }: FilterButtonProps) => (
   <Button
      className={classNames(styles.filterButton, {
         [styles.filterButtonActive]: isActive,
      })}
      variant="plain"
      preserveText
      onClick={onClick}
      minWidth="auto"
      type={'button'}
   >
      <span
         className={classNames(styles.filterButtonIcon, {
            [styles.filterButtonIconActive]: isActive,
         })}
      >
         <Svg id="filters" />
      </span>
      <span>Filters</span>
   </Button>
)

export default memo(FilterButton)
