import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { LoadingOverlay, Map, Modal } from '../../../common';
import { useAllItems } from '../../../../hooks';
import { Pin } from '../../../common/map/map';
import { Item } from '../../../../types';

import { Props } from './itemMapView.type';
import * as styles from './itemMapView.module.scss';

type ItemLocations = {
    [key: string]: {
        lat: number;
        lng: number;
        items: Item[];
    }
}

const ItemMapView: React.FC<Props> = ({ filters }) => {
    const { list, isLoading, isRefetching, refetch } = useAllItems(filters);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        refetch();
    }, [filters]);

    const handlePinSelect = (locationName: string, items: Item[]) => {
        setSelectedLocation({ locationName, items});
        setModalVisible(true);
    }

    const handleCloseModal = () => {
        setModalVisible(false);
        setSelectedLocation(null);
    }

    const itemsGroupedByLocation: ItemLocations = useMemo(() => {
        return list.reduce((itemLocations, item) => {
            const locationLabel = item.CurrentLocation || item.DefaultLocation;

            if (!locationLabel || typeof locationLabel === 'object') {
                return itemLocations;
            }

            if (!item.Latitude || !item.Longitude) {
                return itemLocations;
            }

            return !!itemLocations[locationLabel] ? {
                ...itemLocations,
                [locationLabel]: {
                    ...itemLocations[locationLabel],
                    items: [...itemLocations[locationLabel].items, item]
                }
            } : {
                ...itemLocations,
                [locationLabel]: {
                lat: item.Latitude,
                lng: item.Longitude,
                items: [item],
            }}
        }, {});
    }, [list, filters]);

    const latLngs = useMemo(() =>
        Object.entries(itemsGroupedByLocation).map(([, { lat, lng }]) => ({ lat, lng })), [itemsGroupedByLocation]);

    return (
        <>
            {((isLoading) || !!list.length) && (
                <div className={styles.wrapper}>
                    {(isLoading || isRefetching) && <LoadingOverlay positionAbsolute />}

                    {!!list.length && (
                        <Map markers={latLngs}>
                            {Object.entries(itemsGroupedByLocation).map(([locationLabel, { lat, lng, items }], index) => {
                                if (!lat || !lng) return null;

                                return (
                                    <Pin
                                        key={index}
                                        lat={lat}
                                        lng={lng}
                                        title={locationLabel}
                                        onClick={() => handlePinSelect(locationLabel, items)}
                                    />
                                )
                            })}
                        </Map>
                    )}

                    {modalVisible && (
                        <Modal
                            isModalVisible={modalVisible}
                            closeModal={handleCloseModal}
                            title={selectedLocation?.locationName}
                        >
                            <ul className={styles.itemList}>
                                {selectedLocation?.items.map((item, index) =>
                                    <li className={styles.itemListItem} key={index}>
                                        <span className={classNames(
                                            styles.status,
                                            styles[item.StatusDesc.toLowerCase()]
                                        )} title={item.StatusDesc} />
                                        <span className={styles.title}>{item.Title}</span>
                                    </li>
                                )}
                            </ul>
                        </Modal>
                    )}
                </div>
            )}
        </>
    );
};

export default ItemMapView;