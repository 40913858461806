import React, { useEffect, useState } from 'react';

import { Accordion } from '../..';
import { Toggle } from '../../form/fields';

interface Props {
    className?: string;
    defaultValue?: boolean;
    error?: string;
    id: string;
    onChange: (name: string, label: string, value: boolean) => void;
    title: string;
}

const ToggleFilter: React.FC<Props> = ({
     className,
     defaultValue,
     id,
     onChange,
     title
 }) => {
    const [isOpenInitally, setIsOpenInitially] = useState<boolean>(false);

    const handleFilterChange = (id: string, value: boolean) => {
        onChange(id, '', value);
    }

    useEffect(() => {
        setIsOpenInitially(!!defaultValue);
    }, [])

    return (
        <Accordion
            className={className}
            id={id}
            isOpen={isOpenInitally}
            title={title}
        >
            <Toggle
                id={id}
                size='md'
                isChecked={!!defaultValue}
                onToggle={handleFilterChange}
            />
        </Accordion>
    );
};

export default ToggleFilter;