import { format } from 'date-fns'
import {
   FiltersForAPI,
   Filters as FiltersObject,
   SortObject,
} from '../../../hooks/useFilters/useFilters.type'
import { flattenFilterObj } from '../../../hooks/useFilters/useFilters.helpers'
import { FORMATS } from '../../../constants/datetime'

function formatDate(date: Date) {
   return format(date, FORMATS.filterDateAPI)
}

function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
   const startDateFilter = {
      ...(filters?.DueStartDate?.value instanceof Date && {
         DueStartDate: formatDate(filters?.DueStartDate?.value),
      }),
   }
   const endDateFilter = {
      ...(filters?.DueEndDate?.value instanceof Date && {
         DueEndDate: formatDate(filters?.DueEndDate?.value),
      }),
   }

   return {
      ...flattenFilterObj(filters),
      ...startDateFilter,
      ...endDateFilter,
   }
}

function formatSortForAPI(sort: SortObject, filters: FiltersForAPI) {
   return sort?.sortBy === 'dueBackDate'
      ? {
           ...sort,
           // need to apply this to filters to ignore null due back date items in response
           DueEndDate: filters?.DueEndDate ? filters.DueEndDate : '31 Dec 2099',
        }
      : sort
}

export { formatFiltersForAPI, formatSortForAPI }
